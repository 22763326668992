<template>
  <div>
    <sui-form>
      <h1>{{ $t(`case.properties.arrests`) }}</h1>
      <sui-form-fields :fields="4">
        <sui-form-field>
          <label>{{ $t('case.properties.recipientBankAccount') }}</label>
          <sui-input v-model="recipientBankAccount" />
        </sui-form-field>

        <sui-form-field>
          <label>{{ $t('case.properties.recipientName') }}</label>
          <sui-input v-model="recipientName" />
        </sui-form-field>

        <sui-form-field>
          <label>{{ $t('case.properties.arrestTemplate') }}</label>
          <Select
            v-model="selectedTemplate"
            :options="templateOptions"
            @input="handleTemplateSelect"
          />
        </sui-form-field>
      </sui-form-fields>

      <Editor v-model="arrestTemplate" />

      <sui-form-fields :fields="4" style="margin-top: 0.5rem">
        <sui-form-field>
          <label>{{
            $t('case.properties.personAssociatedWithProperty')
          }}</label>
          <sui-input :value="person" disabled />
        </sui-form-field>

        <sui-form-field>
          <sui-checkbox
            v-model="bankrupt"
            :label="$t('case.properties.bankrupt')"
            style="margin-top: 1.5rem"
          />
        </sui-form-field>
      </sui-form-fields>
      <ArrestTableLegacy
        v-if="hasArrests()"
        :case-id="caseInfo.id"
        :data="arrestList"
        edit-mode
        @refetchArrests="getArrestList()"
        @update="handleArrestsUpdate"
        @toggleQueryViewModal="toggleQueryModal"
      />
      <sui-checkbox
        v-model="noPaymentOrder"
        :label="$t('case.properties.noPaymentOrder')"
      />
      <div class="button__container">
        <sui-button
          v-if="!caseInfo.in_transfer"
          :disabled="!arrestList.find((arrest) => arrest.selected) || isLoading"
          :loading="isLoading"
          @click.prevent="toggleCancelModal"
        >
          {{ $t('case.properties.removeArrest') }}
        </sui-button>
        <sui-button
          v-if="!caseInfo.in_transfer"
          :disabled="!arrestList.find((arrest) => arrest.selected) || isLoading"
          :loading="isLoading"
          primary
          type="button"
          @click.prevent="handleSubmit"
          @keydown.enter.prevent="handleSubmit"
        >
          {{ $t('case.properties.arrest') }}
        </sui-button>
      </div>
    </sui-form>
    <Modal :open="cancelModalOpen" size="mini" @close="toggleCancelModal">
      <template slot="modal-header">
        {{ $t('confirm') }}
      </template>

      <p slot="modal-content">
        {{ $t('case.properties.balance') }}
        {{ caseInfo.balance ? caseInfo.balance.cents / 100 : 0 }}€
      </p>

      <template slot="modal-actions">
        <sui-button
          negative
          :disabled="isLoading"
          :loading="isLoading"
          @click.prevent="handleDeleteArrest"
        >
          {{ $t('case.properties.removeArrest') }}
        </sui-button>
        <sui-button @click.prevent="toggleCancelModal">
          {{ $t('cancel') }}
        </sui-button>
      </template>
    </Modal>
    <QueryViewModal
      :query-id="queryId"
      :open="isQueryViewModalOpen"
      @onToggle="toggleQueryModal"
    />
    <ConfirmArrestsModal
      :visible="confirmArrestsState.isConfirmModalActive"
      @confirm="confirmArrests"
      @cancel="cancelArrests"
    />
  </div>
</template>

<script>
/* eslint-disable camelcase */
import api from '../../../../../api';
import { mapGetters } from 'vuex';
import ArrestTableLegacy from './ArrestTableLegacy.vue';
import ConfirmArrestsModal from './ConfirmArrestsModal.vue';
import Modal from '../../../../../components/Modal.vue';
import Select from '../../../../../components/Select.vue';
import { getFormattedName } from '@/mixins/person';
import { showErrorNotification } from '@/mixins/notification';
import { editEpisArrests, transitionArrest } from '@/mixins/episArrest';
import {
  Statuses,
  ArrestTransition,
  ActiveStatuses,
} from '@/static/enums/arrest';
import { CaseStatus } from '@/static/enums/caseStatus';
import QueryViewModal from '@/components/QueryViewModal.vue';
import { SettingType } from '@/static/enums/settingType';
import { FileTypes } from '@/static/enums/settings';
import Editor from '@/components/Editor';
import { ArrestTarget } from '@/static/enums/bank';
import { ArrestState } from '@/static/enums/arrest';
import { triggerIssuerFeeUpdateModalIfNeeded } from '@/components/Case/triggerIssuerFeeUpdateModalIfNeeded';

export default {
  name: 'Arrests',
  components: {
    ArrestTableLegacy,
    ConfirmArrestsModal,
    Editor,
    Modal,
    Select,
    QueryViewModal,
  },
  props: {
    /**
     * Current case object that was retrieved from v1 api,
     * see EnforcementCase.yml for serialized fields.
     */
    caseInfo: {
      type: Object,
      default: () => {},
    },
    debtorData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      loading: false,
      arrestList: [],
      arrestTemplate: '',
      arrestTemplateNr: '',
      bankrupt: this.debtorData.bankrupt,
      cancelModalOpen: false,
      noPaymentOrder: false,
      recipientBankAccount: '',
      recipientName: '',
      selectedTemplate: null,
      queryId: null,
      isQueryViewModalOpen: false,
      arrestSubmitting: false,
      templates: null,
      confirmArrestsState: {
        isConfirmModalActive: false,
        resolveFn: () => {},
      },
    };
  },
  computed: {
    isLoading() {
      return (
        this.$store.getters['cases/isLoading'] ||
        this.arrestSubmitting ||
        this.loading
      );
    },
    ...mapGetters('settings', ['documentLayoutArrest']),
    person: {
      get() {
        return getFormattedName(this.debtorData);
      },
    },
    templateOptions: {
      get() {
        if (!this.templates) {
          return [];
        }
        return this.templates.value
          .filter(
            (item) =>
              item.value.file_type === FileTypes.ARREST ||
              item.value.file_type === FileTypes.ARREST_REMOVE
          )
          .map((layout) => ({
            display_name: layout.key,
            key: layout.id,
          }));
      },
    },
  },
  async mounted() {
    api.settings
      .fetchByType(
        this.$store.getters['auth/tenant_id'],
        SettingType.DOCUMENT_LAYOUT_ARREST
      )
      .then((response) => {
        [this.templates] = response;
      });
    api.tenantLegacy.find(this.$store.getters['auth/tenant_id']).then((response) => {
      if (response.data.primary_bank_account_id !== null) {
        api.bankAccountLegacy
          .find(response.data.primary_bank_account_id)
          .then((resp) => {
            this.recipientBankAccount = resp.data.accountNo;
            this.recipientName = resp.data.name;
          });
      }
    });
    this.banks = await api.banks.findAllActive();

    await this.getArrestList();
  },
  methods: {
    hasArrests() {
      return this.arrestList.length > 0 && this.banks.length > 0;
    },
    /**
     * Finds all arrests for given case and also gets banks where no arrests have been made
     * to make a single list for ArrestTableLegacy.vue
     * @returns {Promise<void>}
     */
    async getArrestList() {
      let params = {};
      if (this.$route.query.transfer_id) {
        params = {
          transfer_id: this.$route.query.transfer_id,
        };
      }
      const arrestIds = await api.cases
        .findById(this.caseInfo.id, params)
        .then((resp) => resp.data.arrest_ids);

      if (arrestIds.length > 0) {
        this.arrestList = await api.arrest
          .findByIds(arrestIds, {
            ...params,
            limit: arrestIds.length || 0,
          })
          .then((response) => {
            return response.data.items
              .map((arrest) => {
                const bank = this.banks.find(
                  (bankItem) => bankItem.id === arrest.bank_id
                );
                if (bank === undefined) {
                  return null;
                }
                return {
                  ...arrest,
                  selected: false,
                  bank_name: bank ? bank.name : '',
                  bank_preselected: bank ? bank.is_preselected : false,
                  person: this.person,
                  amount_of_min_wages: arrest.amount_of_min_wages || 0,
                  target: bank.target,
                  enforcementCase: `/v2/enforcement_cases/${this.caseInfo.id}`,
                  bank: `/v2/banks/${bank.id}`,
                  state: arrest.state,
                  preArrest: arrest.pre_arrest,
                  lastTransitionError: arrest.last_transition_error,
                  arrest_sum: arrest.arrest_sum
                    ? arrest.arrest_sum
                    : this.arrestSum,
                  dateSet: arrest.date_set,
                  expiresAt: arrest.expires_at,
                  referenceNo: arrest.reference_no,
                };
              })
              .filter(Boolean);
          });
      }

      const banksWithNoArrest = this.banks
        .filter(
          (bank) =>
            !this.arrestList.find((arrest) => arrest.bank_id === bank.id)
        )
        .map((bank) => {
          const { id, name, target } = bank;

          return {
            bank_id: id,
            bank_name: name,
            person: this.person,
            amount_of_min_wages: 0,
            target,
            enforcementCase: `/v2/enforcement_cases/${this.caseInfo.id}`,
            bank: `/v2/banks/${bank.id}`,
            arrest_sum: this.arrestSum,
            selected: false,
            lastTransitionError: null,
            dateSet: null,
            state:
              bank.target === ArrestTarget.EPIS
                ? ArrestState.DRAFT
                : ArrestState.LEGACY,
            expiresAt: null,
          };
        });

      this.arrestList = [...this.arrestList, ...banksWithNoArrest];
      this.arrestList.sort((a, b) => {
        const aIsActive = this.isActiveArrest(a);
        const bIsActive = this.isActiveArrest(b);
        if ((aIsActive && !bIsActive) || (!aIsActive && bIsActive)) {
          return aIsActive ? -1 : 1;
        }

        const bankA = this.banks.find((bank) => bank.id === a.bank_id);
        const bankB = this.banks.find((bank) => bank.id === b.bank_id);

        return bankA.idx - bankB.idx;
      });
    },
    isActiveArrest(arrest) {
      if (arrest.target === ArrestTarget.EPIS) {
        return [
          ArrestState.CREATING,
          ArrestState.ACTIVE,
          ArrestState.UPDATING,
          ArrestState.REMOVING,
        ].includes(arrest.state);
      }
      return ActiveStatuses.includes(arrest.status);
    },
    handleArrestSumUpdate(arrestSum) {
      this.arrestSum = arrestSum;
    },
    handleArrestsUpdate(data) {
      this.arrestList = data;
    },
    handleTemplateSelect(id) {
      const templateItem = this.templates.value.filter(
        (item) => item.id === id
      );
      if (templateItem.length === 0) {
        return;
      }
      this.arrestTemplate = templateItem[0].value.body;
      this.arrestTemplateNr = id;
    },
    async handleSubmit(event) {
      event.preventDefault();
      if (this.caseInfo.status !== 0 && this.caseInfo.status !== 6) {
        showErrorNotification({
          text: 'Toimik ei ole staatuses: Menetluses.',
        });
        return;
      }

      let shouldSetArrests = true;
      if (await this.fetchWhetherCaseHasProblems(this.caseInfo.id)) {
        shouldSetArrests = await new Promise((resolve) => {
          this.confirmArrestsState = {
            isConfirmModalActive: true,
            resolveFn: resolve,
          };
        });
      }

      if (!shouldSetArrests) {
        // Confirmation for arrests was cancelled.
        return;
      }

      this.arrestSubmitting = true;
      this.$store.dispatch('person/update', {
        personId: this.debtorData.id,
        data: {
          bankrupt: this.bankrupt,
          type: this.debtorData.type,
        },
      });

      this.arrestList = this.arrestList.map((arrestListItem) => {
        return {
          ...arrestListItem,
          stopped: this.noPaymentOrder,
        };
      });

      const newArrestReferenceNo = await api.cases.getArrestReferenceNumber(this.caseInfo.id);

      const saveArrestsFn = async () => {
        await Promise.all([
          editEpisArrests(
            this.arrestList.filter(
              (row) => row.selected && row.target === ArrestTarget.EPIS
            ),
            this.getFullTemplate(),
            newArrestReferenceNo
          ),
          this.editEArrestArrests(),
        ]).finally(() => {
          this.arrestSubmitting = false;
          this.getArrestList();
          this.$emit('onChange');
        });
      };

      await triggerIssuerFeeUpdateModalIfNeeded(
        saveArrestsFn,
        this.$store,
        this.$route.params.id,
        true
      );
    },
    async handleDeleteArrest() {
      this.loading = true;
      if (
        this.caseInfo.status !== CaseStatus.OPEN &&
        this.caseInfo.status !== CaseStatus.PARTIALLY_CLOSED &&
        this.caseInfo.status !== CaseStatus.STOPPED
      ) {
        showErrorNotification({
          text: 'Toimik ei ole staatuses: Menetluses.',
        });
        this.loading = false;
        return;
      }
      if (this.arrestTemplateNr === null || this.arrestTemplateNr === '') {
        showErrorNotification({
          text: 'Dokumendi mall on valimata.',
        });
        this.loading = false;
        this.toggleCancelModal();
        return;
      }

      const removeArrestsFn = async () => {
        await Promise.all([
          this.removeEpisArrests(),
          this.removeEArrestArrests(),
        ]).then(() => {
          this.toggleCancelModal();
          this.loading = false;
          this.getArrestList();
          this.$emit('onChange');
        });
      };

      await triggerIssuerFeeUpdateModalIfNeeded(
        removeArrestsFn,
        this.$store,
        this.$route.params.id,
        true
      );
    },
    removeEpisArrests() {
      const list = this.arrestList.filter(
        (row) =>
          row.selected &&
          row.target === ArrestTarget.EPIS &&
          row.state === ArrestState.ACTIVE
      );
      return Promise.all(
        list.map((item) => {
          return transitionArrest(
            item,
            ArrestTransition.ACTIVE_TO_REMOVING,
            this.getFullTemplate()
          );
        })
      );
    },
    removeEArrestArrests() {
      const list = this.arrestList.filter(
        (row) => row.selected && row.target === ArrestTarget.EARREST
      );
      if (list.length > 0) {
        const data = {
          template: this.getFullTemplate(),
          arrest_ids: list
            .flatMap((arrest) => {
              const { id, bank_id } = arrest;
              if (bank_id === null || bank_id === '') {
                showErrorNotification({
                  text: `Valitud pangal ${arrest.bank_name} ei ole võimalik aresti eemaldada, puudub id.`,
                });
                return 0;
              }
              return id;
            })
            .filter(Boolean),
        };
        this.arrestSubmitting = true;
        return api.cases.removeArrests(this.caseInfo.id, data);
      }
    },
    editEArrestArrests() {
      const rowList = this.arrestList.filter(
        (row) => row.selected && row.target === ArrestTarget.EARREST
      );
      const data = {
        template: this.getFullTemplate(),
        arrests: rowList.map((arrest) => {
          const {
            amount_of_min_wages,
            bank_id,
            id,
            sum_not_arrested,
            preArrest,
            arrest_sum,
          } = arrest;
          return {
            id,
            amount_of_min_wages: amount_of_min_wages || 0,
            status: id ? Statuses.IN_ARREST_CHANGE : Statuses.IN_ARREST,
            arrest_sum,
            arrest_type: this.noPaymentOrder
              ? 'MAKSEJUHISETA'
              : 'MAKSEJUHISEGA',
            bank_id,
            enforcement_case_id: this.caseInfo.id,
            sum_not_arrested,
            changed: true,
            preArrest,
          };
        }),
      };
      return api.cases.arrest(this.caseInfo.id, data);
    },
    getFullTemplate() {
      if (this.selectedTemplate === null) {
        showErrorNotification({
          text: this.$t('error.no_template_selected'),
        });
        this.arrestSubmitting = false;
        throw Error('Template not selected');
      }
      const templateItem = this.templates.value.filter(
        (item) => item.id === this.arrestTemplateNr
      );
      if (templateItem.length === 0) {
        return;
      }
      const template = templateItem[0].value;
      return template.header + this.arrestTemplate + template.footer;
    },
    toggleCancelModal() {
      this.cancelModalOpen = !this.cancelModalOpen;
    },
    toggleQueryModal(id) {
      this.queryId = id;
      this.isQueryViewModalOpen = !this.isQueryViewModalOpen;
    },
    confirmArrests() {
      if (!this.confirmArrestsState.isConfirmModalActive) {
        return;
      }

      this.confirmArrestsState.resolveFn(true);
      this.confirmArrestsState.isConfirmModalActive = false;
    },
    cancelArrests() {
      if (!this.confirmArrestsState.isConfirmModalActive) {
        return;
      }

      this.confirmArrestsState.resolveFn(false);
      this.confirmArrestsState.isConfirmModalActive = false;
    },
    /**
     * Returns true (problems found for case) or false (no problems found).
     */
    async fetchWhetherCaseHasProblems(caseId) {
      return await api.problem
        .findByCase(caseId, 1, 10)
        .then((respone) => respone['hydra:member'].length > 0);
    },
  },
};
</script>

<style scoped>
.button__container {
  text-align: right;
  width: 100%;
}
</style>
