/* eslint-disable vue/no-mutating-props */
<template>
  <sui-segment>
    <sui-form @submit.prevent="save">
      <sui-grid divided>
        <sui-grid-column :width="2">
          <sui-form-fields fields="one">
            <sui-form-field>
              <label>{{ $t('case.info.deliveryDate') }}</label>
              <DatePicker v-model="info.delivery_date" :disabled="disabled" />
            </sui-form-field>
          </sui-form-fields>
          <sui-form-fields fields="one">
            <sui-form-field>
              <label>{{ $t('case.info.voluntaryComplianceDate') }}</label>
              <DatePicker v-model="info.voluntary_compliance_date" :disabled="disabled" />
            </sui-form-field>
          </sui-form-fields>
          <sui-form-fields fields="one">
            <sui-form-field>
              <label>{{ $t('case.info.enforcementDate') }}</label>
              <DatePicker v-model="info.enforcement_date" disabled />
            </sui-form-field>
          </sui-form-fields>
          <sui-form-fields fields="one">
            <sui-form-field>
              <label>{{ $t('case.info.prePayment') }}</label>
              <CurrencyInputNumber v-model="info.pre_payment" :disabled="disabled" />
            </sui-form-field>
          </sui-form-fields>
        </sui-grid-column>

        <sui-grid-column :width="2">
          <sui-form-fields fields="one">
            <sui-form-field>
              <label>{{ $t('case.info.claimSum') }}</label>
              <CurrencyInputNumber v-model="caseInfo.total_claims" disabled />
            </sui-form-field>
          </sui-form-fields>
          <sui-form-fields fields="one">
            <sui-form-field>
              <label>{{ $t('case.info.transactionsSum') }}</label>
              <CurrencyInputNumber v-model="caseInfo.sum_received" :disabled="disabled" />
            </sui-form-field>
          </sui-form-fields>
          <sui-form-fields fields="one">
            <sui-form-field>
              <label>
                {{ $t('case.info.balance') }}
                <i
                  v-if="!disabled"
                  v-tooltip="$t('recalc')"
                  class="pi pi-refresh"
                  @click="reCalcBalance"
                />
              </label>
              <CurrencyInputNumber :value="caseInfo.balance" disabled />
            </sui-form-field>
          </sui-form-fields>
          <sui-form-fields fields="one">
            <sui-form-field>
              <label>{{ $t('case.info.paidToClaimant') }}</label>
              <CurrencyInputNumber v-model="info.paid_to_claimant" :disabled="disabled" />
            </sui-form-field>
          </sui-form-fields>
        </sui-grid-column>

        <sui-grid-column :width="3">
          <sui-form-fields fields="two">
            <sui-form-field>
              <label>{{ $t('case.info.startFeeLeft') }}</label>
              <CurrencyInputNumber v-model="info.start_fee" :disabled="disabled" />
            </sui-form-field>
            <sui-form-field>
              <label>{{ $t('case.info.startFee') }}</label>
              <CurrencyInputNumber
                v-model="info.start_fee_initial"
                :disabled="disabled"
              />
            </sui-form-field>
          </sui-form-fields>
          <sui-form-fields fields="two">
            <sui-form-field>
              <label>{{ $t('case.info.issuerFeeLeft') }}</label>
              <CurrencyInputNumber v-model="info.issuer_fee" :disabled="disabled" />
            </sui-form-field>
            <sui-form-field>
              <label>
                {{ $t('case.info.issuerFee') }}
                <i
                    v-if="!disabled"
                    v-tooltip="$t('case.check.issuer_fee')"
                    class="pi pi-refresh"
                    @click="checkIssuerFee"
                /></label>
              <CurrencyInputNumber
                v-model="info.issuer_fee_initial"
                :disabled="disabled"
              />
            </sui-form-field>
          </sui-form-fields>
          <sui-form-fields fields="two">
            <sui-form-field>
              <label>{{ $t('case.info.enforcementFeeLeft') }}</label>
              <CurrencyInputNumber v-model="info.enforcement_fee" :disabled="disabled" />
            </sui-form-field>
            <sui-form-field>
              <label>{{ $t('case.info.enforcementFee') }}</label>
              <CurrencyInputNumber
                v-model="info.enforcement_fee_initial"
                :disabled="disabled"
              />
            </sui-form-field>
          </sui-form-fields>
          <sui-form-fields fields="two">
            <sui-form-field>
              <label>{{ $t('case.info.tableFee') }}</label>
              <CurrencyInputNumber v-model="info.table_fee" :disabled="disabled" />
            </sui-form-field>
          </sui-form-fields>
        </sui-grid-column>

        <sui-grid-column :width="2">
          <sui-form-fields fields="one">
            <sui-form-field>
              <label>
                {{ $t('case.info.arrestStart') }}
                <i
                  v-tooltip="$t('tooltip.arrest_first_set')"
                  class="pi pi-question-circle"
                ></i>
              </label>
              <InputText :value="getFormattedDate(eArrestDateSet)" disabled />
            </sui-form-field>
          </sui-form-fields>
          <sui-form-fields fields="one">
            <sui-form-field>
              <label>
                {{ $t('case.info.arrestEnd') }}
                <i
                  v-tooltip="$t('tooltip.final_arrest_removed')"
                  class="pi pi-question-circle"
                ></i>
              </label>
              <InputText :value="getFormattedDate(eArrestDateEnd)" disabled />
            </sui-form-field>
          </sui-form-fields>
          <sui-form-fields fields="one">
            <sui-form-field>
              <sui-checkbox
                :input-value="eArrestChanged"
                :label="$t('case.info.arrestChanged')"
                disabled
              />
            </sui-form-field>
          </sui-form-fields>
        </sui-grid-column>
        <sui-grid-column :width="3">
          <template v-if="arrests.length > 0">
            <div v-for="(arrest, index) in arrests" :key="index" class="bank__container">
              <Bank :arrest="arrest" />
            </div>
          </template>

          <div v-else class="arrest-info">
            {{ $t('arrest.none') }}
          </div>

          <div v-if="arrests.find((bank) => !bank.bank_id)" class="arrest-info">
            {{ $t('arrest.unknown') }}
          </div>
        </sui-grid-column>

        <sui-grid-column :width="3">
          <sui-form-field>
            <label for="dateGraph">{{ $t('case.info.dateGraph') }}</label>
            <Calendar
              id="dateGraph"
              v-model="dateGraph"
              :show-time="false"
              :disabled="disabled"
              append-to="body"
            />
          </sui-form-field>

          <sui-form-field>
            <div>
              <Checkbox
                id="stopped"
                v-model="info.stopped"
                :disabled="disabled"
                :binary="true"
              />
              <label class="info__checkbox-label">
                {{ getStoppedLabel() }}
              </label>

              <ToolTip
                v-if="
                  info.stopped &&
                  info.stop_reason &&
                  info.stop_reason.length > 25
                "
                :text="info.stop_reason"
              />
            </div>
          </sui-form-field>

          <sui-form-field>
            <div>
              <Checkbox
                id="alimonyRelated"
                v-model="info.is_alimony_related"
                :disabled="disabled"
                :binary="true"
              />
              <label class="info__checkbox-label">
                {{ $t('case.info.isAlimonyRelated') }}
              </label>
            </div>
          </sui-form-field>

          <sui-form-field>
            <div>
              <Checkbox
                id="actSent"
                v-model="info.act_sent"
                :disabled="disabled"
                :binary="true"
              />
              <label class="info__checkbox-label">
                {{ $t('case.info.actSent') }}
              </label>
            </div>
          </sui-form-field>

          <div class="button__container">
            <span
              v-if="disabled && !info.in_transfer"
              v-tooltip.top="{
                value: $t('case.fieldsDisabled', {
                  state: $t(CaseStateToLabel[caseState]),
                }),
                disabled: isEditable,
              }"
            >
              <sui-button size="mini" :disabled="!isEditable" @click.prevent="edit">
                {{ $t('edit') }}
              </sui-button>
            </span>

            <sui-button
              v-if="!disabled && !info.in_transfer"
              size="mini"
              @click.prevent="cancel"
            >
              {{ $t('cancel') }}
            </sui-button>

            <sui-button
              v-if="!disabled && !info.in_transfer"
              :loading="isLoading"
              primary
              size="mini"
              type="submit"
            >
              {{ $t('save') }}
            </sui-button>
          </div>
        </sui-grid-column>
      </sui-grid>
    </sui-form>
  </sui-segment>
</template>

<script>
import api from '@/api';
import Bank from '../Bank.vue';
import DatePicker from '../../../../components/DatePicker.vue';
import { CaseStatus } from '@/static/enums/caseStatus';
import CurrencyInputNumber from '@/components/CurrencyInputNumber.vue';
import ToolTip from '@/components/Tooltip.vue';
import { triggerIssuerFeeUpdateModalIfNeeded } from '@/components/Case/triggerIssuerFeeUpdateModalIfNeeded';
import { getFormattedDate } from '@/mixins/dateTime';
import {
  ActiveStatuses,
  ArrestState,
  ChangedStatuses,
} from '@/static/enums/arrest';
import { CaseStateToLabel } from '@/static/enums/caseStateToLabel';
import EventBus from '@/mixins/event-bus';
import { showErrorNotification } from '@/mixins/notification';
import { isCaseEditable } from '@/utils/isCaseEditable';

export default {
  name: 'Info',
  components: {
    CurrencyInputNumber,
    Bank,
    DatePicker,
    ToolTip,
  },
  props: {
    /**
     * Current case object that was retrieved from v1 api,
     * see EnforcementCase.yml for serialized fields.
     */
    caseInfo: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      arrestInfo: {},
      arrestedBanks: [],
      banks: [],
      eArrestDateSet: null,
      eArrestDateEnd: null,
      eArrestChanged: false,
      claimInfo: {},
      disabled: true,
      info: {},
      isLoading: false,
      CaseStateToLabel,
    };
  },
  computed: {
    arrests: {
      get() {
        return this.arrestedBanks.filter(
          (arrestedBank) =>
            ActiveStatuses.indexOf(arrestedBank.status) > -1 ||
            arrestedBank.state === ArrestState.ACTIVE ||
            arrestedBank.state === ArrestState.UPDATING
        );
      },
    },
    dateGraph: {
      get() {
        if (!this.info.date_graph) {
          return '';
        }
        return new Date(this.info.date_graph);
      },
      set(value) {
        try {
          this.info.date_graph = new Date(
            value.getTime() - value.getTimezoneOffset() * 60000
          );
        } catch (e) {
          this.info.date_graph = null;
        }
      },
    },
    caseState() {
      return this.caseInfo.state;
    },
    /**
     * Whether the case can be edited (true) or not (false).
     */
    isEditable() {
      return isCaseEditable(this.caseInfo);
    },
  },
  async created() {
    this.banks = await this.$store.dispatch('banks/getBanks');

    this.$watch(
      'caseInfo',
      (caseInfo) => {
        if (caseInfo && caseInfo.id) {
          this.info = {
            ...caseInfo,
            stopped: caseInfo.status === CaseStatus.STOPPED,
          };
          this.getArrests();
          this.getMainClaim(caseInfo.id);
        }
      },
      { immediate: true }
    );
  },
  methods: {
    getArrests() {
      if (this.caseInfo.arrest_ids.length === 0) {
        return;
      }
      let params = {};
      if (this.$route.query.transfer_id) {
        params = {
          transfer_id: this.$route.query.transfer_id,
        };
      }
      api.arrest.findByIds(this.caseInfo.arrest_ids, params).then((response) => {
        const { data } = response;
        if (data && data.items.length > 0) {
          const [arrest] = data.items;
          this.arrestInfo = arrest;
          // Check for earliest earrest set date
          const earliest = data.items.sort(
            (a1, a2) => new Date(a1.date_set) - new Date(a2.date_set)
          );
          earliest.forEach((item) => {
            if (item.date_set !== null && this.eArrestDateSet === null) {
              this.eArrestDateSet = this.getFormattedDate(item.date_set);
            }
          });
          // Check for latest earrest remove
          const countActive = data.items.filter(
            (arrestedBank) => ActiveStatuses.indexOf(arrestedBank.status) > -1
          );
          if (countActive.length === 0) {
            const latest = data.items.sort(
              (a1, a2) => new Date(a2.date_closed) - new Date(a1.date_closed)
            );
            latest.forEach((item) => {
              if (item.date_closed !== null && this.eArrestDateEnd === null) {
                this.eArrestDateEnd = this.getFormattedDate(item.date_closed);
              }
            });
          }
          // Check if any arrest is changed
          const countChanged = data.items.filter(
            (arrestedBank) => ChangedStatuses.indexOf(arrestedBank.status) > -1
          );
          if (countChanged.length !== 0) {
            this.eArrestChanged = true;
          }
          this.arrestedBanks = data.items.map((arrestItem) => {
            const bank = this.banks.find(
              (bankItem) => bankItem.id === arrestItem.bank_id
            );
            return {
              ...arrestItem,
              bankName: bank ? bank.name : '',
            };
          });
        }
      });
    },
    getMainClaim(id) {
      api.claim.findMainClaimByCaseId(id).then((response) => {
        const { data } = response;

        if (data && data.length > 0) {
          const [claim] = data;
          this.claimInfo = claim;
        }
      });
    },
    editActSent(event) {
      api.cases.update(this.caseInfo.id, {
        act_sent: event.target.checked,
      });
    },
    cancel() {
      this.disabled = true;
    },
    edit() {
      this.disabled = false;
    },
    async save() {
      this.isLoading = true;
      const { stopped, ...rest } = this.info;

      const saveCaseFn = async () => {
        await api.cases.update(this.info.id, {
          ...rest,
          sum_received: this.caseInfo.sum_received,
        });

        this.$emit('onChange');
      };

      try {
        await triggerIssuerFeeUpdateModalIfNeeded(
          saveCaseFn,
          this.$store,
          this.$route.params.id,
          false
        );

        // Disable form again after successful save.
        this.disabled = true;
      } catch (e) {
        showErrorNotification('Toimiku salvestamine ebaõnnestus.');
      } finally {
        this.isLoading = false;
      }
    },
    getStoppedLabel() {
      const label = this.$t('case.info.stopped');
      if (this.info.stopped && this.info.stop_reason) {
        if (this.info.stop_reason.length < 25) {
          return `${label} (${this.info.stop_reason})`;
        }
        return `${label} (${this.info.stop_reason.substring(0, 25)}...)`;
      }
      return label;
    },
    reCalcBalance() {
      api.cases.recalc(this.caseInfo.id).then(() => EventBus.$emit('RELOAD_CASE_DATA'));
    },
    checkIssuerFee() {
      this.$store.dispatch('cases/toggleIssuerFeeUpdateModal');
    },
    getFormattedDate,
  },
};
</script>

<style scoped>
.ui.segment {
  margin-bottom: 1rem;
}

.ui.divided.grid > .column.no-border {
  box-shadow: none;
}
.ui.form .field .ui.input,
.ui.form .fields .field .ui.input,
.ui.form .wide.field .ui.input {
  width: 90%;
}

.bank__container {
  display: inline-block;
  text-align: center;
  width: 50%;
}

.button__container {
  bottom: 0;
  position: absolute;
  right: 0.5rem;
}

.arrest-info {
  font-style: italic;
  padding-top: 4rem;
  text-align: center;
}

.info__checkbox-label {
  margin-left: 0.25rem !important;
}
</style>
