<template>
  <sui-segment>
    <h2>{{ $t('case.solution.title') }}</h2>
    <sui-form @submit.prevent="saveSolution">
      <sui-grid :columns="2">
        <sui-grid-row>
          <sui-grid-column>
            <sui-grid>
              <sui-grid-column :width="16" :large-screen="4" :widescreen="4">
                <h3>{{ $t('case.solution.title') }}</h3>
              </sui-grid-column>

              <sui-grid-column :width="16" :large-screen="12" :widescreen="12">
                <sui-form-field>
                  <label>{{ $t('case.solution.solutionType') }}</label>
                  <Select
                    v-model="solution.type"
                    :disabled="disabled"
                    :options="solutionTypeOptions"
                    @input="handleSolutionTypeChange"
                  />
                </sui-form-field>

                <sui-form-field>
                  <label>{{ $t('case.solution.resolver') }}</label>
                  <Select
                    v-model="solution.resolver"
                    :disabled="disabled"
                    :options="resolverOptions"
                    @input="handleInput"
                  />
                </sui-form-field>

                <sui-form-field>
                  <label>{{ $t('case.solution.judgment') }}</label>
                  <Select
                    v-model="solution.resolvingType"
                    :disabled="disabled"
                    :options="resolvingTypeOptions"
                    @input="handleInput"
                  />
                </sui-form-field>
              </sui-grid-column>
            </sui-grid>
          </sui-grid-column>

          <sui-grid-column>
            <sui-grid>
              <sui-grid-column :width="16" :large-screen="6" :widescreen="6">
                <h3 v-html="$t('case.solution.decision')" />
              </sui-grid-column>

              <sui-grid-column :width="16" :large-screen="10" :widescreen="10">
                <sui-form-field>
                  <label>{{ $t('case.solution.documentNumber') }}</label>
                  <Input
                    v-model="solution.decisionNumber"
                    :disabled="disabled"
                    icon="file alternate outline"
                    @input="handleInput"
                  />
                </sui-form-field>

                <sui-form-field required>
                  <label>{{ $t('case.solution.decisionDate') }}</label>
                  <DatePicker
                    v-model="solution.decisionDate"
                    :disabled="disabled"
                    @input="handleInput"
                  />
                </sui-form-field>

                <sui-form-field required>
                  <label>{{ $t('case.solution.enactmentDate') }}</label>
                  <DatePicker
                    v-model="solution.enforceStartDate"
                    :disabled="disabled"
                    @input="handleInput"
                  />
                </sui-form-field>

                <sui-form-field>
                  <label>{{ $t('case.solution.expirationDate') }}</label>
                  <DatePicker
                    v-model="solution.enforceEndDate"
                    :disabled="disabled"
                    @input="handleInput"
                  />
                </sui-form-field>
              </sui-grid-column>
            </sui-grid>
          </sui-grid-column>
        </sui-grid-row>

        <sui-grid-row>
          <sui-grid-column>
            <sui-grid>
              <sui-grid-column :width="16" :large-screen="4" :widescreen="4">
                <h3 v-html="$t('case.solution.statement')" />
              </sui-grid-column>

              <sui-grid-column :width="16" :large-screen="12" :widescreen="12">
                <sui-form-field>
                  <label>{{ $t('case.solution.statementNumber') }}</label>
                  <Input
                    v-model="taitisNumber"
                    :disabled="disabled"
                    @input="handleInput"
                  />
                </sui-form-field>
                <sui-form-field>
                  <label>{{ $t('case.solution.tms') }}</label>
                  <Select
                    v-model="solution.tms"
                    :disabled="disabled"
                    :options="tmsOptions"
                    @input="handleInput"
                  />
                </sui-form-field>
              </sui-grid-column>
            </sui-grid>
          </sui-grid-column>
        </sui-grid-row>
      </sui-grid>

      <div v-if="!inTransfer && showButtons" class="button__container">
        <sui-divider />

        <span
          v-if="disabled"
          v-tooltip.top="{
            value: $t('case.fieldsDisabled', {
              state: $t(CaseStateToLabel[caseState]),
            }),
            disabled: isEditable,
          }"
        >
          <sui-button
            size="mini"
            :disabled="!isEditable"
            @click.prevent="disabled = false"
          >
            {{ $t('edit') }}
          </sui-button>
        </span>

        <sui-button v-if="!disabled" size="mini" @click.prevent="disabled = true">
          {{ $t('cancel') }}
        </sui-button>

        <sui-button v-if="!disabled" :loading="loading" primary size="mini" type="submit">
          {{ $t('save') }}
        </sui-button>
      </div>
    </sui-form>
  </sui-segment>
</template>

<script>
import api from '@/api';
import Input from '../Input.vue';
import Select from '../Select.vue';
import DatePicker from '../DatePicker.vue';
import { showErrorNotification, showSuccessNotification } from '@/mixins/notification';
import { SettingType } from '@/static/enums/settingType';
import { triggerIssuerFeeUpdateModalIfNeeded } from '@/components/Case/triggerIssuerFeeUpdateModalIfNeeded';
import { CaseStateToLabel } from '@/static/enums/caseStateToLabel';
import { isCaseEditable } from '@/utils/isCaseEditable';
import EventBus from '@/mixins/event-bus';

export default {
  name: 'Solution',
  components: {
    DatePicker,
    Input,
    Select,
  },
  props: {
    /**
     * Current case object that was retrieved from v1 api,
     * see EnforcementCase.yml for serialized fields.
     */
    caseInfo: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      loading: false,
      disabled: true,
      taitisNumber: null,
      inTransfer: false,
      showButtons: true,
      solution: {},
      caseResolvers: null,
      resolvingTypes: null,
      allowedTMS: null,
      solutionTypes: null,
      CaseStateToLabel,
    };
  },
  computed: {
    resolverOptions: {
      get() {
        if (!this.caseResolvers) return [];

        return this.caseResolvers.value;
      },
    },
    resolvingTypeOptions: {
      get() {
        if (!this.resolvingTypes) {
          return [];
        }

        const tempList = JSON.parse(JSON.stringify(this.resolvingTypes.value));
        for (let i = 0; i < tempList.length; i++) {
          const name = tempList[i].display_name;
          tempList[i].display_name = name.charAt(0).toUpperCase() + name.slice(1);
        }

        return tempList;
      },
    },
    tmsOptions: {
      get() {
        if (!this.allowedTMS) {
          return [];
        }
        return JSON.parse(JSON.stringify(this.allowedTMS.value));
      },
    },
    solutionTypeOptions: {
      get() {
        if (!this.solutionTypes) {
          return [];
        }

        const tempList = JSON.parse(JSON.stringify(this.solutionTypes.value));
        for (let i = 0; i < tempList.length; i++) {
          const name = tempList[i].display_name;
          tempList[i].display_name = name.charAt(0).toUpperCase() + name.slice(1);
        }

        return tempList;
      },
    },
    caseState() {
      return this.caseInfo.state;
    },
    /**
     * Whether the case can be edited (true) or not (false).
     */
    isEditable() {
      return isCaseEditable(this.caseInfo);
    },
  },
  created() {
    Promise.all([
      api.settings.fetchByType(
        this.$store.getters['auth/tenant_id'],
        SettingType.CASE_RESOLVER
      ),
      api.settings.fetchByType(
        this.$store.getters['auth/tenant_id'],
        SettingType.RESOLVING_TYPE
      ),
      api.settings.fetchByType(
        this.$store.getters['auth/tenant_id'],
        SettingType.ALLOWED_TMS
      ),
      api.settings.fetchByType(
        this.$store.getters['auth/tenant_id'],
        SettingType.CASE_SOLUTION_TYPE
      ),
    ]).then(([caseResolvers, resolvingTypes, allowedTMS, solutionTypes]) => {
      [this.caseResolvers] = caseResolvers;
      [this.resolvingTypes] = resolvingTypes;
      [this.allowedTMS] = allowedTMS;
      [this.solutionTypes] = solutionTypes;
    });
    this.$watch(
      'caseInfo',
      (eCase) => {
        if (undefined === eCase) {
          this.disabled = false;
          this.showButtons = false;
          return;
        }
        let params = {};
        if (this.$route.query.transfer_id) {
          params = {
            transfer_id: this.$route.query.transfer_id,
          };
        }
        this.taitisNumber = eCase.taitis_number;
        this.inTransfer = eCase.in_transfer;
        this.getSolution(eCase.id, params);
      },
      { immediate: true }
    );
  },
  methods: {
    getSolution(id, params) {
      api.solution.findByCaseId(id, params).then((response) => {
        if (response.data && response.data.length > 0) {
          const [solution] = response.data;
          this.solution = solution;
        }
      });
    },
    handleSolutionTypeChange() {
      this.handleInput();
    },
    handleInput() {
      this.$emit('update', this.solution);
    },
    async saveSolution() {
      this.loading = true;

      const saveSolutionFn = async () => {
        if (this.solution.id) {
          await this.updateSolution(this.solution);
        } else {
          await this.createSolution(this.solution);
        }

        await api.cases.update(this.caseInfo.id, {
          taitis_number: this.taitisNumber === '' ? null : this.taitisNumber,
        });

        EventBus.$emit('RELOAD_CASE_DATA');
      };

      await triggerIssuerFeeUpdateModalIfNeeded(
        saveSolutionFn,
        this.$store,
        this.$route.params.id,
        true
      );
    },
    async createSolution(data) {
      await api.solution
        .create({
          ...data,
          enforcementCase: this.caseInfo.id,
        })
        .then((response) => {
          this.disabled = true;
          this.solution = response.data;

          showSuccessNotification({
            text: this.$t('notification.saveSuccess'),
          });
        })
        .catch(() => {
          showErrorNotification({
            title: this.$t('notification.saveError'),
            text: this.$t('notification.saveErrorDescription'),
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async updateSolution(data) {
      await api.solution
        .update(data.id, data)
        .then((response) => {
          this.disabled = true;
          this.solution = response.data;

          showSuccessNotification({
            text: this.$t('notification.saveSuccess'),
          });
        })
        .catch(() => {
          showErrorNotification({
            title: this.$t('notification.saveError'),
            text: this.$t('notification.saveErrorDescription'),
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.button__container {
  margin-top: 1rem;
  text-align: right;
}
</style>
