import req from '@/api/lib/req';
import { BulkActionStatus } from '@/static/enums/bulkActionStatus';
import { Collection } from '@/types/ApiPlatform/Collection';
import { Single } from '@/types/ApiPlatform/Single';
import { BulkActionSaved } from '@/types/BulkAction';
import { BulkActionTaskSaved } from '@/types/BulkActionTask';

/**
 * Finds a paged result of bulk action tasks of given status. If no status
 * is passed, then any status of tasks are found.
 */
export async function findByBulkActionPaged(
  bulkAction: BulkActionSaved,
  status: BulkActionStatus | null,
  caseNumber: string,
  order?: Record<string, 'asc'|'desc'> | null,
  page?: number,
  limit?: number
): Promise<Collection<BulkActionTaskSaved>> {
  const filters: string[] = [];

  if (status !== null) {
    filters.push(`status=${status}`);
  }

  if (caseNumber !== '') {
    filters.push(`case.number=${caseNumber}`);
  }

  if (order) {
    Object.entries(order).forEach(([field, direction]) => {
      filters.push(`order[${field}]=${direction}`);
    });
  }

  const filterParamsJoined = filters.length > 0 ? `&${filters.join('&')}` : '';

  return req
    .get(`/v2/bulk_action_tasks?bulkAction=${bulkAction.id}${filterParamsJoined}&page=${page || 1}&limit=${limit || 50}`)
    .then((resp) => resp.data);
}

/**
 * Finds 1 wip task, 1 failed task and 1 cancelled task for given bulk action.
 * If one, two or none of those tasks are found then the corresponding index
 * value in array is given as NULL.
 *
 * 3 elements are always returned:
 *   0 - WIP bulk action task or NULL if not found.
 *   1 - FAILED bulk action task or NULL if not found.
 *   2 - CANCELLED bulk action task or NULL if not found.
 */
export async function findOneWipFailedAndCancelledTaskByBulkAction(
  action: BulkActionSaved
): Promise<
  [
    BulkActionTaskSaved | null,
    BulkActionTaskSaved | null,
    BulkActionTaskSaved | null
  ]
> {
  return req
    .get(`/v2/tasks_wip_and_failed_of_bulk_action?bulkActionId=${action.id}`)
    .then(({ data }) => data['hydra:member']);
}

/**
 * Reschedules the given bulk action task for retrying.
 */
export async function retry(
  bulkActionTask: BulkActionTaskSaved
): Promise<Single<BulkActionTaskSaved>> {
  return req
    .put(`/v2/bulk_action_tasks/${bulkActionTask.id}/retry`)
    .then((resp) => resp.data);
}

/**
 * Updates given bulk action task resource.
 */
export async function update(
  bulkActionTask: BulkActionTaskSaved
): Promise<Single<BulkActionTaskSaved>> {
  return req
    .put(`/v2/bulk_action_tasks/${bulkActionTask.id}`, bulkActionTask)
    .then((resp) => resp.data);
}
