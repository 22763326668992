/**
 * Metadata options for a classifier value.
 * NB: Same values are defined in ClassifierValue.php - Keep in sync!
 */
export const META_LAW = 'law';
export const META_PARTIAL_END = 'partial_end';
export const META_EXPIRE = 'expire';
export const META_DEATH_DEBTOR = 'death_debtor';
export const META_DEATH_CLAIMANT = 'death_claimant';
export const META_PARTIAL_END_TARN_ID = 'partial_end_tarn_id';
export const META_PARTIAL_END_TARN_V2_ID = 'partial_end_tarn_v2_id';
