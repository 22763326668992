import req from '@/api/lib/req';
import { Id } from '@/types';
import { Collection } from '@/types/ApiPlatform/Collection';
import { ProblemSaved } from '@/types/Problem';

/**
 * Finds a paged result of bulk actions from a single date.
 */
export async function findByCase(
  caseId: Id,
  page?: number,
  limit?: number
): Promise<Collection<ProblemSaved>> {
  return req
    .get(`/v2/problems?case=${caseId}&page=${page || 1}&limit=${limit || 50}`)
    .then((resp) => resp.data);
}