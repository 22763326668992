/**
 * Enums for TAPAIS query types.
 * NB: Same values as in Entity/Query/Interfaces/QueryInterface.php! Keep in sync.
 */
export enum QueryType {
  BANKACCOUNT = 0,
  ARREST = 1, // Aresti seadmine
  ARREST_CANCEL = 2, // Aresti eemaldamine
  ARREST_CHANGE = 3, // Aresti muutmine
  RECEIVED_RESPONSES = 4, // Mulle saadetud asjad
  DOCUMENT_TEMPLATE = 5, // Dokumendi genereermine
  TARN_LIST = 6, // TARN-i toimikud
  OK_SYNC = 7, // Oksjonikeskuse kasutajate sync
  TARN_EDIT = 9, // TARN-is muutmine
  TARN_ADD = 10, // TARN-i lisamine
  TARN_NTK_ADD = 11, // TARN-i NTK lisamine
  TARN_BALANCE = 12, // TARN-i toimiku kontroll
  TARN_NTK_CHANGE = 13, // TARN-is NTK muutmine

  // NB: These are defined in `TarnV2Query`, please keep in sync.
  TYPE_TARN2_OPEN = 1001,
  TYPE_TARN2_TRANSFER = 1002,
  TYPE_TARN2_CLOSE = 1003,
  TYPE_TARN2_PARTIAL_CLOSE = 1004,
  TYPE_TARN2_EDIT = 1006,
  TYPE_TARN2_STOP = 1007,
  TYPE_TARN2_STOP_CANCEL = 1008,
  TYPE_TARN2_REOPEN = 1009,
}
