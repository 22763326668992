<template>
  <section>
    <h1>Päringud</h1>
    <DataTable
      :value="queries"
      responsive-layout="scroll"
      :loading="loading"
      :lazy="true"
      :row-class="rowClass"
      @page="onPage($event)"
    >
      <Column field="issuer_name" :header="$t('queries.issuer')"></Column>
      <Column :header="$t('queries.bank')">
        <template #body="slotProps">
          {{ getBankName(slotProps.data) }}
        </template>
      </Column>
      <Column :header="$t('queries.type')">
        <template #body="slotProps">
          {{ formatQueryType(slotProps.data) }}
        </template>
      </Column>
      <Column :header="$t('queries.status')">
        <template #body="slotProps">
          {{ getQueryStatus(slotProps.data.status) }}
        </template>
      </Column>
      <Column :header="$t('queries.time')">
        <template #body="slotProps">
          {{ getFormattedDateTime(slotProps.data.created_at) }}
        </template>
      </Column>
      <Column>
        <template #body="slotProps">
          <sui-button
            :loading="isLoading"
            @click="openQueryModal(slotProps.data)"
          >
            Vaata
          </sui-button>
          <sui-button
            v-if="
              slotProps.data.status === 4 ||
              slotProps.data.status === 1 ||
              slotProps.data.status === 2 ||
              slotProps.data.status === 0
            "
            :loading="isLoading"
            @click="resend(slotProps.data.id)"
          >
            Uuesti
          </sui-button>
        </template>
      </Column>
    </DataTable>

    <QueryViewModal
      :query-id="queryId"
      :open="isQueryViewModalOpen"
      :query-type="queryType"
      @onToggle="toggleQueryModal"
    />
  </section>
</template>

<script>
import api from '../../../../api';
import QueryViewModal from '../../../../components/QueryViewModal.vue';
import { getFormattedDateTime } from '@/mixins/dateTime';
import { queryTypes, queryStatuses } from '@/static/enums/query';
import { showErrorNotification } from '@/mixins/notification';
import { TarnEditTypes } from '@/static/enums/TarnEditTypes';

export default {
  name: 'QueriesTab',
  components: {
    QueryViewModal,
  },
  data() {
    return {
      queries: [],
      loading: false,
      banks: [],
      arrests: [],
      queryId: null,
      queryType: null,
      isQueryViewModalOpen: false,
      isLoading: false,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.isLoading = true;
      const { id } = this.$route.params;
      let params = {};
      if (this.$route.query.transfer_id) {
        params = {
          transfer_id: this.$route.query.transfer_id,
        };
      }
      const [queries, banks, arrests] = await Promise.all([
        api.cases.fetchQueries(id, params),
        this.$store.dispatch('banks/getBanks'),
        api.arrest.fetchCaseArrests(id),
      ]);
      this.queries = queries.data;
      this.banks = banks;
      this.arrests = arrests;
      this.isLoading = false;
    },
    getQueryStatus(status) {
      return queryStatuses[status];
    },
    formatQueryType(query) {
      const type = queryTypes[query.type] || `Tundmatu tüüp (${query.type})`;

      if ('tarn_edit' === query.dtype) {
          // eslint-disable-next-line no-case-declarations
          const editType = JSON.parse(query.request).liik;
          const editTypeAsStr = TarnEditTypes[editType] || `tundmatu ${editType}`
          return `${queryTypes[query.type]} (${editTypeAsStr})`;
      }

      if ('arrest' === query.dtype) {
        // For some reason arrest queries dont include ".type" property.
        return 'Arest';
      }

      if ('change_arrest' === query.dtype) {
        // For some reason arrest queries dont include ".type" property.
        return 'Aresti muutmine';
      }

      if ('cancel_arrest' === query.dtype) {
        // For some reason arrest queries dont include ".type" property.
        return 'Aresti katkestamine';
      }

      return type;
    },
    openQueryModal(query) {
      this.queryId = query.id;
      this.queryType = query.type;
      this.toggleQueryModal();
    },
    toggleQueryModal() {
      this.isQueryViewModalOpen = !this.isQueryViewModalOpen;
    },
    resend(id) {
      this.isLoading = true;
      api.query
        .retry(id)
        .then(() => {
          this.getData();
        })
        .catch((error) => showErrorNotification({ text: error }))
        .finally(() => {
          this.isLoading = false;
        });
    },
    getBankName(query) {
      let bank;
      if (this.banks.length === 0) {
        return '';
      }
      const request = JSON.parse(query.request);
      switch (query.dtype) {
        case 'document_template':
          if (request.bank_id) {
            bank = this.banks.find((curBank) => curBank.id === request.bank_id);
            return bank ? bank.name : '';
          }
          return '';
        case 'arrest':
        case 'cancel_arrest':
        case 'change_arrest':
          const arrest = this.arrests.find(
            (arrestItem) => arrestItem.id === query.arrest_id
          );
          if (!arrest) {
            return '';
          }
          bank = this.banks.find(
            (curBank) => curBank.id === arrest.bank.replace('/v2/banks/', '')
          );
          return bank ? bank.name : '';
        case 'received_responses':
          const response = JSON.parse(query.response);
          if (response.AsutuseNimetus) {
            return response.AsutuseNimetus;
          }
          return '';
        default:
          return '';
      }
    },
    rowClass(data) {
      switch (data.status) {
        case 3:
          return 'positive-row';
        case 4:
          return 'negative-row';
        default:
          return '';
      }
    },
    onPage(event) {
      this.perPage = event.rows;
      this.page = event.page;
      this.getData();
    },
    getFormattedDateTime,
  },
};
</script>
<style scoped>
.ui.table td {
  vertical-align: top;
}

.ui.icon.button {
  padding: 0.5rem;
}

.queries__container {
  max-height: 20px;
  overflow: hidden;
}

.queries__container--visible {
  overflow: visible;
}

.query__subheader {
  font-weight: bold;
  font-style: italic;
  color: red;
}
</style>
