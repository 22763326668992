<template>
  <sui-grid-column>
    <sui-form @submit="handleSubmit">
      <Person
        :disabled="disabled"
        :initial-data="personData"
        :is-loading="isLoading"
        :role="role"
        :case-info="caseInfo"
        @cancel="disabled = true"
        @edit="disabled = false"
        @personDataChange="handleChange"
      />
    </sui-form>
  </sui-grid-column>
</template>
<script>
import api from '@/api';
import { Types } from '@/static/enums/person';
import Person from '@/components/Person/Person.vue';
import { removeEmptyValues } from '@/mixins/util';
import {
  showSuccessNotification,
  showErrorNotification,
} from '../../../../mixins/notification';
import { triggerIssuerFeeUpdateModalIfNeeded } from '@/components/Case/triggerIssuerFeeUpdateModalIfNeeded';
import EventBus from '@/mixins/event-bus';

export default {
  name: 'PersonData',
  components: {
    Person,
  },
  props: {
    personData: {
      type: Object,
      default: () => {},
    },
    role: {
      type: String,
      required: true,
    },
    /**
     * Current case object that was retrieved from v1 api,
     * see EnforcementCase.yml for serialized fields.
     */
    caseInfo: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      data: this.personData,
      disabled: true,
      isLoading: false,
      Types,
    };
  },
  watch: {
    personData(data) {
      this.data = data;
    },
  },
  methods: {
    handleChange(role, data) {
      this.data = data;
    },
    async handleSubmit(event) {
      event.preventDefault();

      await triggerIssuerFeeUpdateModalIfNeeded(
        this.save,
        this.$store,
        this.$route.params.id,
        true
      );
    },
    async save() {
      this.isLoading = true;

      const {
        // eslint-disable-next-line camelcase
        canonical_lvl,
        accountsData,
        addressData,
        contactsData,
        id,
        representativesData,
        ...rest
      } = this.data;

      const [contacts, addresses, bankAccounts, representatives] =
        await Promise.all([
          this.saveContacts(contactsData, canonical_lvl),
          this.saveAddresses(addressData, canonical_lvl),
          this.saveBankAccounts(accountsData, canonical_lvl),
          this.saveRepresentatives(representativesData, canonical_lvl),
        ]);

      const finalData = {
        ...removeEmptyValues(rest),
        contacts,
        addresses,
        bankAccounts,
        representatives,
      };
      // eslint-disable-next-line camelcase
      if (id && canonical_lvl === 3) {
        await api.person
          .update(id, finalData)
          .then((response) => {
            this.disabled = true;

            showSuccessNotification({
              text: this.$t('notification.saveSuccess'),
            });

            this.$emit('personSave', response.data.id);
          })
          .catch(() => {
            showErrorNotification({
              title: this.$t('notification.saveError'),
              text: this.$t('notification.saveErrorDescription'),
            });
          })
          .finally(() => {
            this.isLoading = false;
            EventBus.$emit('RELOAD_CASE_DATA');
          });
      } else {
        await api.person
          .create(finalData)
          .then((response) => {
            this.disabled = true;

            showSuccessNotification({
              text: this.$t('notification.saveSuccess'),
            });

            this.$emit('personSave', response.data.id);
          })
          .catch(() => {
            showErrorNotification({
              title: this.$t('notification.saveError'),
              text: this.$t('notification.saveErrorDescription'),
            });
          })
          .finally(() => {
            this.isLoading = false;
            EventBus.$emit('RELOAD_CASE_DATA');
          });
      }
    },
    async saveContacts(contacts, canonicalLvl) {
      const promises = contacts.map(async (contact) => {
        if (contact.id && canonicalLvl === 3) {
          const { data: editedContact } = await api.contacts.change(
            contact.id,
            contact
          );
          return editedContact.id;
        }
        // eslint-disable-next-line no-param-reassign
        contact.id = null;
        const { data: newContact } = await api.contacts.create(contact);
        return newContact.id;
      });

      return Promise.all(promises);
    },
    async saveAddresses(addresses, canonicalLvl) {
      const filtered = addresses.filter(
        (el) =>
          el != null && el.address !== undefined && el.address.trim() !== ''
      );
      const promises = filtered.map(async (address) => {
        if (address.id && canonicalLvl === 3) {
          const { data } = await api.addresses.update(address.id, address);
          return data.id;
        }
        // eslint-disable-next-line no-param-reassign
        address.id = null;
        const { data } = await api.addresses.create(address);
        return data.id;
      });

      return Promise.all(promises);
    },
    async saveBankAccounts(bankAccounts, canonicalLvl) {
      const promises = bankAccounts.map(async (account) => {
        if (account.id && canonicalLvl === 3) {
          const bankAccount = removeEmptyValues(account);
          delete bankAccount.person;
          delete bankAccount.bank;

          const { data } = await api.banks.update(account.id, bankAccount);
          return data.id;
        }

        // eslint-disable-next-line no-param-reassign
        account.id = null;
        const { data } = await api.banks.create(account);
        return data.id;
      });

      return Promise.all(promises);
    },
    async saveRepresentatives(representatives) {
      const promises = representatives.map(async (representative) => {
        const { data } = await api.person.create({
          ...representative,
          represent_basis: representative.represent_basis.toString(),
        });
        return data.id;
      });

      return Promise.all(promises);
    },
  },
};
</script>
