import FeatureFlag from "../FeatureFlag.vue";

export default {
  name: 'PartialSidenav',
    components: { FeatureFlag },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classList() {
      return {
        open: this.open,
      };
    },
  },
};
