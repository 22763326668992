<template>
  <ui-page>
    <div class="settings">
      <PanelMenu :model="links" />
      <component :is="currentSubPage.component" v-if="isVisible" />
    </div>
  </ui-page>
</template>
<script>
import { mapGetters } from 'vuex';
import PanelMenu from 'primevue/panelmenu';
import pageNameSync from '../../mixins/pageNameSync';

export default {
  components: {
    PanelMenu,
  },
  mixins: [pageNameSync],
  data() {
    return {
      links: [
        {
          label: 'Büroo',
          name: 'bureau',
          icon: 'pi pi-fw pi-building',
          to: { name: 'Settings', params: { subPage: 'bureau' } },
          component: () => import('./pages/Bureau.vue'),
        },
        {
          label: 'Kasutaja',
          name: 'user',
          icon: 'pi pi-fw pi-user',
          to: { name: 'Settings', params: { subPage: 'user' } },
          component: () => import('./pages/User.vue'),
        },
        {
          label: 'Parameetrid',
          name: 'parameters',
          icon: 'pi pi-fw pi-building',
          to: { name: 'Settings', params: { subPage: 'parameters' } },
          component: () => import('./pages/Parameters.vue'),
        },
        {
          label: 'Mallid',
          name: 'template',
          icon: 'pi pi-fw pi-building',
          to: { name: 'Settings', params: { subPage: 'template' } },
          component: () => import('./pages/Document.vue'),
        },
        {
          label: 'Järjekorrad',
          name: 'queue',
          icon: 'pi pi-fw pi-building',
          to: { name: 'Settings', params: { subPage: 'queue' } },
          component: () => import('./pages/Queue.vue'),
        },
        {
          label: 'Päringud',
          name: 'queries',
          icon: 'pi pi-fw pi-building',
          to: { name: 'Settings', params: { subPage: 'queries' } },
          visible: () => this.isRoleKoda,
          component: () => import('./pages/query/Query.vue'),
        },
        {
          label: 'Sätted',
          name: 'settings',
          icon: 'pi pi-fw pi-cog',
          visible: () => this.isRoleKoda,
          items: [
            {
              label: 'Klassifikaatorid',
              name: 'classifiers',
              to: { name: 'Settings', params: { subPage: 'classifiers' } },
              visible: () => this.isRoleKoda,
              component: () => import('./pages/Classifiers.vue'),
            },
            {
              label: 'Põhitasu määrad',
              name: 'issuerFeeTables',
              to: { name: 'Settings', params: { subPage: 'issuerFeeTables' } },
              visible: () => this.isRoleKoda,
              component: () =>
                import('./pages/IssuerFeeTables/IssuerFeeTables.vue'),
            },
            {
              label: this.$t('settings.claimTypes'),
              name: 'claimTypeTables',
              to: { name: 'Settings', params: { subPage: 'claimTypeTables' } },
              visible: () => this.isRoleKoda,
              component: () => import('./pages/ClaimTypes/ClaimTypes.vue'),
            },
          ],
        },
      ],
    };
  },
  computed: {
    ...mapGetters('settings', ['settings']),
    ...mapGetters('user', ['isRoleKoda']),
    currentSubPage: {
      get() {
        const { subPage } = this.$route.params;
        return this.findCurrentSubPage(subPage, this.links) || this.links[0];
      },
    },
  },
  created() {
    this.$store.dispatch(
      'settings/getSettings',
      this.$store.getters['auth/tenant_id']
    );
  },
  destroyed() {
    this.$store.dispatch('settings/clearSettings');
  },
  methods: {
    findCurrentSubPage(needle, haystack) {
      if (undefined === needle) {
        return null;
      }
      return haystack.reduce((found, candidate) => {
        if (found) {
          return found;
        }

        if (needle === candidate.name) {
          return candidate;
        }

        if (!('items' in candidate)) {
          return null;
        }
        return this.findCurrentSubPage(needle, candidate.items);
      }, null);
    },
    isVisible() {
      return (
        this.currentSubPage &&
        this.currentSubPage.visible() &&
        this.settings &&
        this.settings.length > 0
      );
    },
  },
};
</script>
<style>
.settings {
  display: grid;
  grid-template-columns: 200px 1fr;
}
</style>
