<template>
  <div>
    <sui-form-field>
      <Radio
        v-model="model.type"
        :choices="typeOptions"
        :disabled="disabled"
        @input="onChangeType"
      />
    </sui-form-field>

    <sui-form-fields :fields="2">
      <sui-form-field>
        <label style="white-space: nowrap">{{ $t('person.info.code') }}</label>
        <InputTooltip :text="model.regCode">
          <ui-search
            v-model="model.regCode"
            :disabled="disabled"
            :results.sync="search.results"
            :loading="search.isLoading"
            :opened.sync="search.isOpen"
            category
            @result-select="onResultSelect"
            @search-blur="handleSearchBlur"
            @search-change="onSearchChange"
          />
        </InputTooltip>
      </sui-form-field>

      <sui-form-field v-if="!disabled" class="registry__wrapper">
        <sui-button
          v-if="!isJuridical"
          :loading="loadingRegisterData"
          size="mini"
          type="button"
          @click.prevent="queryFromPopulationRegister"
        >
          {{ $t('person.info.dataFromRegister') }}
        </sui-button>

        <sui-button
          v-if="isJuridical"
          :loading="loadingRegisterData"
          size="mini"
          type="button"
          @click.prevent="queryFromBusinessRegister"
        >
          {{ $t('person.info.dataFromRegister') }}
        </sui-button>
      </sui-form-field>
    </sui-form-fields>

    <sui-form-fields v-if="!isJuridical" :fields="2">
      <sui-form-field required>
        <label>{{ $t('person.info.firstName') }}</label>
        <InputTooltip :text="model.nameFirst">
          <Input
            v-model="model.nameFirst"
            :disabled="disabled"
            icon="user"
            @input="handleInput"
          />
        </InputTooltip>
      </sui-form-field>

      <sui-form-field required>
        <label>{{ $t('person.info.lastName') }}</label>
        <InputTooltip :text="model.nameLast">
          <Input
            v-model="model.nameLast"
            :disabled="disabled"
            icon="user"
            @input="handleInput"
          />
        </InputTooltip>
      </sui-form-field>
    </sui-form-fields>

    <sui-form-fields v-if="isJuridical">
      <sui-form-field :width="16" required>
        <label>{{ $t('person.info.name') }}</label>
        <InputTooltip :text="model.name">
          <Input
            v-model="model.name"
            :disabled="disabled"
            icon="user"
            @input="handleInput"
          />
        </InputTooltip>
      </sui-form-field>
    </sui-form-fields>

    <sui-form-fields v-if="!isJuridical" :fields="2">
      <sui-form-field>
        <label>{{ $t('person.info.date_of_birth') }}</label>
        <InputTooltip :text="model.dateBirth">
          <DatePicker
            v-model="model.dateBirth"
            :disabled="disabled"
            @input="handleInput"
          />
        </InputTooltip>
      </sui-form-field>

      <sui-form-field>
        <label>{{ $t('person.info.gender') }}</label>
        <Radio
          v-model="model.gender"
          :choices="genderOptions"
          :disabled="disabled"
          @input="handleInput"
        />
      </sui-form-field>
    </sui-form-fields>

    <sui-form-fields v-if="isJuridical" :fields="2">
      <sui-form-field>
        <label>{{ $t('person.info.date_of_formation') }}</label>
        <InputTooltip :text="model.dateCreated">
          <DatePicker
            v-model="model.dateCreated"
            :disabled="disabled"
            @input="handleInput"
          />
        </InputTooltip>
      </sui-form-field>
    </sui-form-fields>
    <sui-form-fields :fields="2">
      <sui-form-field>
        <label v-if="isJuridical">{{
          $t('person.info.date_of_liquidation')
        }}</label>
        <label v-else>{{ $t('person.info.date_of_death') }}</label>
        <DatePicker
          v-model="model.date_death"
          :disabled="disabled"
          @input="handleInput"
        />
      </sui-form-field>
    </sui-form-fields>

    <sui-form-fields :fields="2">
      <sui-form-field required>
        <label>{{ $t('person.info.country') }}</label>
        <Select
          v-model="model.country"
          :label="$t('person.info.country')"
          :disabled="disabled"
          icon="globe"
          :options="countryOptions"
          @input="handleInput"
        />
      </sui-form-field>
    </sui-form-fields>
  </div>
</template>

<script>
import api from '../../api';
import DatePicker from '../DatePicker.vue';
import Input from '../Input.vue';
import InputTooltip from '../InputTooltip.vue';
import Radio from '../Radio.vue';
import { ContactTypes, Genders, Types } from '../../static/enums/person';
import {
  showSuccessNotification,
  showErrorNotification,
} from '../../mixins/notification';
import Select from '../Select.vue';
import { TYPE_COUNTRY } from '../../static/enums/classifier';

const PERSONAL_CODE_LENGTH = 11;
const REGISTER_CODE_LENGTH = 8;

export default {
  name: 'PersonInfo',
  components: {
    Select,
    DatePicker,
    Input,
    InputTooltip,
    Radio,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      countryOptions: [],
      model: {},
      searchTimeout: null,
      genderOptions: [
        { value: Genders.MALE, label: this.$i18n.t('person.genders.male') },
        { value: Genders.FEMALE, label: this.$i18n.t('person.genders.female') },
        {
          value: Genders.UNKNOWN,
          label: this.$i18n.t('person.genders.unknown'),
        },
      ],
      typeOptions: [
        { value: Types.PRIVATE, label: this.$i18n.t('person.type.private') },
        {
          value: Types.JURIDICAL,
          label: this.$i18n.t('person.type.juridical'),
        },
      ],
      search: {
        isLoading: false,
        isOpen: false,
        results: [],
      },
      loadingRegisterData: false,
    };
  },
  computed: {
    isJuridical: {
      get() {
        return this.model.type === Types.JURIDICAL;
      },
    },
  },
  created() {
    this.loadData();

    this.$watch(
      'data',
      (data) => {
        this.model = { ...data };
      },
      { immediate: true }
    );
  },
  methods: {
    async loadData() {
      const [countries] = await Promise.all([
        this.$store.dispatch('classifierValue/findByName', TYPE_COUNTRY),
      ]);
      this.countryOptions = countries.map((country) => ({
        display_name: country.name,
        key: country.id,
      }));
    },
    handleInput() {
      this.$emit('update', this.model);
    },
    handleSearchBlur() {
      this.search = {
        isLoading: false,
        isOpen: false,
        results: [],
      };
    },
    onResultSelect(result) {
      if (result) {
        this.$emit('search', result.id);
      }
    },
    onSearchChange(value) {
      this.model.regCode = value;
      this.$emit('update', this.model);

      if (!value || value.length < 3) {
        return;
      }

      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.search.isLoading = true;

        api.person.quickSearch(value).then((results) => {
          this.search = {
            results: results.data,
            isLoading: false,
            isOpen: !(Array.isArray(results.data) && results.data.length === 0),
          };
        });
      }, 500);
    },
    queryFromBusinessRegister() {
      if (this.model.regCode.length === REGISTER_CODE_LENGTH) {
        this.loadingRegisterData = true;

        api.businessRegister
          .findById(this.model.regCode)
          .then((response) => {
            if (response.data.name) {
              const { address, ...rest } = response.data;

              const addressData = [...this.model.addressData];

              if (
                address &&
                !addressData.find((item) => item.address === address)
              ) {
                addressData.push({
                  address,
                });
              }

              this.model = {
                ...this.model,
                addressData,
                ...rest,
              };

              this.$emit('update', this.model);
              showSuccessNotification({
                title: this.$t('notification.querySuccess'),
              });
            } else {
              showErrorNotification({
                title: this.$t('notification.queryError'),
              });
            }
          })
          .catch(() => {
            showErrorNotification({
              title: this.$t('notification.queryError'),
            });
          })
          .finally(() => {
            this.loadingRegisterData = false;
          });
      } else {
        showErrorNotification({
          title: this.$t('notification.registerCodeLength'),
        });
      }
    },
    queryFromPopulationRegister() {
      if (this.model.regCode.length === PERSONAL_CODE_LENGTH) {
        this.loadingRegisterData = true;

        api.populationRegister
          .findById(this.model.regCode)
          .then((response) => {
            const {
              // eslint-disable-next-line camelcase
              address,
              email,
              phone_number,
              ...rest
            } = response.data;

            const addressData = [...this.model.addressData];
            const contactsData = [...this.model.contactsData];

            if (address) {
              addressData.push({
                address,
              });
            }

            if (email) {
              contactsData.push({
                type: ContactTypes.EMAIL,
                value: email,
              });
            }

            // eslint-disable-next-line camelcase
            if (phone_number) {
              contactsData.push({
                type: ContactTypes.PHONE,
                value: phone_number,
              });
            }

            this.model = {
              ...this.model,
              addressData,
              contactsData,
              ...rest,
            };

            this.$emit('update', this.model);
            showSuccessNotification({
              title: this.$t('notification.querySuccess'),
            });
          })
          .catch(() => {
            showErrorNotification({
              title: this.$t('notification.queryError'),
            });
          })
          .finally(() => {
            this.loadingRegisterData = false;
          });
      } else {
        showErrorNotification({
          title: this.$t('notification.personCodeLength'),
        });
      }
    },
    onChangeType() {
      this.model.id = '';
      this.$emit('update', this.model);
    },
  },
};
</script>

<style scoped>
.ui.button {
  width: 100%;
}

.registry__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
</style>
