<template>
  <sui-segment>
    <sui-form @submit.prevent="save">
      <sui-grid>
        <sui-grid-column>
          <sui-form-field>
            <label>{{ $t('case.info.recipientName') }}</label>
            <Input v-model="model.recipient_name" :disabled="disabled" />
          </sui-form-field>

          <sui-form-field>
            <label>{{ $t('case.info.recipientBankAccount') }}</label>
            <Input
              v-model="model.recipient_bank_account"
              :disabled="disabled"
            />
          </sui-form-field>

          <sui-form-field>
            <label>{{ $t('case.info.recipientRegCode') }}</label>
            <Input v-model="model.recipient_reg_code" :disabled="disabled" />
          </sui-form-field>

          <div class="button__container">
            <span
              v-if="disabled && !caseInfo.in_transfer"
              v-tooltip.top="{
                value: $t('case.fieldsDisabled', {
                  state: $t(CaseStateToLabel[caseState]),
                }),
                disabled: isEditable,
              }"
            >
              <sui-button
                size="mini"
                :disabled="!isEditable"
                @click.prevent="edit"
              >
                {{ $t('edit') }}
              </sui-button>
            </span>

            <sui-button
              v-if="!disabled && !caseInfo.in_transfer"
              size="mini"
              @click.prevent="cancel"
            >
              {{ $t('cancel') }}
            </sui-button>

            <sui-button
              v-if="!disabled && !caseInfo.in_transfer"
              :loading="isLoading"
              primary
              size="mini"
              type="submit"
            >
              {{ $t('save') }}
            </sui-button>
          </div>
        </sui-grid-column>
      </sui-grid>
    </sui-form>
  </sui-segment>
</template>

<script>
import api from '../../../../api';
import Input from '../../../../components/Input.vue';
import { triggerIssuerFeeUpdateModalIfNeeded } from '@/components/Case/triggerIssuerFeeUpdateModalIfNeeded';
import { CaseStateToLabel } from '@/static/enums/caseStateToLabel';
import {
  showErrorNotification,
  showSuccessNotification,
} from '@/mixins/notification';
import { isCaseEditable } from '@/utils/isCaseEditable';
import EventBus from '@/mixins/event-bus';

export default {
  name: 'Payment',
  components: {
    Input,
  },
  props: {
    /**
     * Current case object that was retrieved from v1 api,
     * see EnforcementCase.yml for serialized fields.
     */
    caseInfo: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      disabled: true,
      isLoading: false,
      model: {
        recipient_name: '',
      },
      CaseStateToLabel,
    };
  },
  computed: {
    caseState() {
      return this.caseInfo.state;
    },
    /**
     * Whether the case can be edited (true) or not (false).
     */
    isEditable() {
      return isCaseEditable(this.caseInfo);
    },
  },
  created() {
    // eslint-disable-next-line camelcase
    const { recipient_bank_account, recipient_name, recipient_reg_code } =
      this.caseInfo;

    this.model = {
      recipient_bank_account,
      recipient_name,
      recipient_reg_code,
    };
  },
  methods: {
    cancel() {
      this.disabled = true;
    },
    edit() {
      this.disabled = false;
    },
    async save() {
      this.isLoading = true;

      const savePaymentFn = async () => {
        await api.cases
          .update(this.caseInfo.id, this.model)
          .then(() => {
            showSuccessNotification({ text: 'Andmed edukalt salvestatud' });
            this.cancel();
          })
          .catch(() => {
            showErrorNotification({ text: 'Andmete salvestamine ebaõnnestus' });
          })
          .finally(() => {
            this.isLoading = false;
          });

        EventBus.$emit('RELOAD_CASE_DATA');
      };

      await triggerIssuerFeeUpdateModalIfNeeded(
        savePaymentFn,
        this.$store,
        this.$route.params.id,
        true
      );
    },
  },
};
</script>

<style scoped>
.ui.segment {
  margin-bottom: 1rem;
}

.button__container {
  text-align: right;
}
</style>
