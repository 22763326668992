import * as addresses from './modules/addresses';
import * as arrest from './modules/arrest';
import * as auth from './modules/auth';
import * as bankAccount from './modules/bankAccount';
import * as bankAccountLegacy from './modules/bankAccountLegacy';
import * as banks from './modules/banks';
import * as bulkAction from './modules/bulkAction';
import * as bulkActionTask from './modules/bulkActionTask';
import * as bureau from './modules/bureau';
import * as businessRegister from './modules/businessRegister';
import * as cases from './modules/cases';
import * as caseSelection from './modules/caseSelection';
import * as caseTable from './caseTable/caseTable';
import * as caseTransfer from './modules/caseTransfer';
import * as claim from './modules/claim';
import * as claimTypes from './modules/claimType.ts';
import * as classifier from './modules/classifier';
import * as classifierValueLegacy from './modules/classifierValueLegacy';
import * as classifierValue from './modules/classifierValue';
import * as contacts from './modules/contacts';
import * as cron from './modules/cron';
import * as document from './modules/document';
import * as errorLog from './modules/errorLog';
import * as issuerFeeRow from './modules/issuerFeeRow.ts';
import * as issuerFeeTable from './modules/issuerFeeTable.ts';
import * as log from './modules/log';
import * as person from './modules/person';
import * as populationRegister from './modules/populationRegister';
import * as problem from './modules/problem';
import * as query from './modules/query';
import * as settings from './modules/settings';
import * as solution from './modules/solution';
import * as surcharge from './modules/surcharge';
import * as tags from './modules/tag';
import * as tenantLegacy from './modules/tenantLegacy';
import * as users from './modules/users';

export default {
  addresses,
  arrest,
  auth,
  bankAccount,
  bankAccountLegacy,
  banks,
  bulkAction,
  bulkActionTask,
  bureau,
  businessRegister,
  cases,
  caseSelection,
  caseTable,
  caseTransfer,
  claim,
  claimTypes,
  classifier,
  classifierValueLegacy,
  classifierValue,
  contacts,
  cron,
  document,
  errorLog,
  issuerFeeRow,
  issuerFeeTable,
  log,
  person,
  populationRegister,
  problem,
  query,
  settings,
  solution,
  surcharge,
  tags,
  tenantLegacy,
  users,
};
