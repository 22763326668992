import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';
import SignIn from '../pages/SignIn/SignIn.vue';
import ChooseRole from '../pages/ChooseRole/ChooseRole.vue';
import CaseListLegacy from '../pages/CaseListLegacy/CaseListLegacy.vue';
import CaseList from '../pages/CaseList/CaseList.vue';
import BulkActions from '../pages/BulkActions/BulkActions.vue';
import BulkAction from '../pages/BulkAction/BulkAction.vue';
import Case from '../pages/Case/Case.vue';
import Dashboard from '../pages/Dashboard/Dashboard.vue';
import CaseNew from '../pages/CaseNew/CaseNew.vue';
import Settings from '../pages/Settings/Settings.vue';
import { SET_TENANT_ID, SET_TOKEN } from '@/store/modules/auth';

Vue.use(VueRouter);

const routes = [
  {
    path: '/dashboard/:filter?',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      pageName: 'Tegevuslaud',
      layout: 'DefaultLayout',
    },
  },
  {
    path: '*',
    redirect: {
      name: 'Dashboard',
    },
  },
  {
    path: '/cases/:search?',
    name: 'CaseListLegacy',
    component: CaseListLegacy,
    meta: {
      pageName: 'Täitetoimikud',
      layout: 'DefaultLayout',
    },
  },
  {
    path: '/case_list/:caseSelectionId?',
    name: 'CaseList',
    component: CaseList,
    meta: {
      pageName: 'Täitetoimikud',
      layout: 'DefaultLayout'
    },
  },
  {
    path: '/bulk_actions',
    name: 'BulkActions',
    component: BulkActions,
    meta: {
      pageName: 'Masstegevused',
      layout: 'DefaultLayout',
    },
  },
  {
    path: '/bulk_actions/:id',
    name: 'BulkAction',
    component: BulkAction,
    meta: {
      pageName: 'Masstegevus',
      layout: 'DefaultLayout',
    },
  },
  {
    path: '/signin',
    name: 'SignIn',
    component: SignIn,
    meta: {
      pageName: 'KPKoda',
      layout: 'EmptyLayout',
      secure: false,
    },
  },
  {
    path: '/logout',
    name: 'logout',
    beforeEnter(to, from, next) {
      store.dispatch('user/signOut').finally(() => {
        store.commit(`auth/${SET_TOKEN}`, null);
        store.commit(`auth/${SET_TENANT_ID}`, null);
        next({
          name: 'SignIn',
        });
      });
    },
  },
  {
    path: '/choose-role',
    name: 'ChooseRole',
    component: ChooseRole,
    meta: {
      pageName: 'KPKoda',
      layout: 'EmptyLayout',
    },
  },
  {
    path: '/case/:id/:tab?',
    name: 'Case',
    component: Case,
    meta: {
      caseStatus: '',
      pageName: 'Täitetoimik',
      layout: 'DefaultLayout',
    },
  },
  {
    path: '/case-new',
    name: 'CaseNew',
    component: CaseNew,
    meta: {
      pageName: 'Uus täitetoimik',
      layout: 'DefaultLayout',
    },
  },
  {
    path: '/settings/:subPage?/:group?',
    name: 'Settings',
    component: Settings,
    meta: {
      pageName: 'Seaded',
      layout: 'DefaultLayout',
    },
  },
];
const router = new VueRouter({
  mode: 'history',
  routes,
  // eslint-disable-next-line no-unused-vars
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.name !== 'SignIn')) {
    if (store.getters['auth/isAuthenticated']) {
      next();
    } else {
      next({
        name: 'SignIn',
      });
    }
  } else {
    const token = Vue.$cookies.get('koda-token');
    const id = Vue.$cookies.get('koda-id');
    if (token !== null) {
      store.commit(`auth/${SET_TOKEN}`, token);
      localStorage.setItem('user-id', id);
    }
    next();
  }
});
export default router;
