<template>
  <div>
    <sui-accordion v-for="(representative, index) in model" :key="index">
      <sui-divider />
      <sui-accordion-title :tabindex="0" @keypress.enter="representativeToggle">
        <sui-icon name="dropdown" />
        <h3>{{ $t('person.representatives.title') }}</h3>
      </sui-accordion-title>

      <sui-accordion-content>
        <Representative
          v-if="representationBases"
          :data="representative"
          :disabled="disabled"
          :representation-bases="representationBases"
          @created="(firstItem) => openRepresentative(firstItem, index)"
          @edit="(data) => editRepresentative(index, data)"
        />

        <div class="remove">
          <DeletePopover
            v-if="!disabled"
            :text="$t('person.representatives.remove')"
            @confirm="() => removeRepresentative(index)"
          />
        </div>
      </sui-accordion-content>
    </sui-accordion>

    <sui-divider />

    <sui-grid>
      <sui-grid-column :width="2">
        <h3>{{ $t('person.representatives.title') }}</h3>
      </sui-grid-column>

      <sui-grid-column :width="14" class="add">
        <sui-button
          v-if="!disabled"
          size="tiny"
          type="button"
          @click.prevent="addRepresentative"
        >
          {{ $t('person.representatives.add') }}
        </sui-button>
      </sui-grid-column>
    </sui-grid>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DeletePopover from '../DeletePopover.vue';
import Representative from './Representative.vue';

export default {
  name: 'PersonRepresentatives',
  components: {
    DeletePopover,
    Representative,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    representatives: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      representationBases: [],
    };
  },
  computed: {
    ...mapGetters('settings', ['representativeType']),
    model: {
      get() {
        return [...this.representatives];
      },
    },
  },
  created() {
    this.$watch(
      'representativeType',
      (representativeType) => {
        if (representativeType) {
          this.getRepresentationBases(representativeType.value);
        }
      },
      { immediate: true }
    );
  },
  methods: {
    addRepresentative() {
      const representatives = [...this.model];
      representatives.push({});

      this.$emit('update', representatives);
    },
    editRepresentative(index, data) {
      const representatives = [...this.model];
      representatives[index] = data;

      this.$emit('update', representatives);
    },
    openRepresentative(firstItem, index) {
      const newestRepresentative =
        document.getElementsByClassName('ui accordion')[index];
      const title = newestRepresentative.querySelector('.title');

      // Need this to make sure DOM renders first
      setTimeout(() => {
        title.click();
      }, 0);
    },
    removeRepresentative(index) {
      const representatives = [...this.model];
      representatives.splice(index, 1);

      this.$emit('update', representatives);
    },
    getRepresentationBases(bases) {
      this.representationBases = [...bases];
    },
    representativeToggle(event) {
      // This is necessary because semantic-ui-vue doesn't
      // handle accordion toggle events with keyboard
      event.target.click();
    },
  },
};
</script>

<style scoped>
.ui.accordion h3 {
  display: inline-block;
  margin-top: 0;
}

.ui.accordion .title {
  display: inline-block;
  margin-left: -0.5rem;
  min-width: 12.5%;
  padding-right: 0.5rem;
  text-align: right;
}

.ui.accordion .content {
  position: relative;
}

.add {
  text-align: right;
}

.remove {
  text-align: right;
}

@media (min-width: 1200px) {
  .remove {
    bottom: 0;
    position: absolute;
    right: 0;
  }
}
</style>
